<template >

  <pc-header></pc-header>
    
    <div style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;">
      <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">Checkout</div>
      <div> 
        <router-link to="/">
          <el-link href="" >Home</el-link> 
        </router-link>
       
        /  
        <span >Checkout</span>
      </div>
    </div>

    <el-row :gutter="30" style="min-width: 1300px;width: 75vw;margin: 80px auto;">
      <el-col :span="14">
      <el-card style="height: 920px;background-color: #fff;border-radius: 1%;" title="Shipping Address">
        <template #header>
          <div style="display: flex;font-size: 24px;font-weight: 600;">
            <span>Shipping Address</span>
          </div>
        </template>
        <div style="font-size: 18px;font-weight: 600;margin: auto;width:350px" v-if="islogin">You haven't logged in yet, 
          <router-link to="/pages/login?redirect=/pages/order">
            <el-link href="" style="color: #e11414;font-size: 18px;vertical-align: middle;font-weight: 600;margin-bottom: 5px;">Login</el-link>
          </router-link>
          
           here</div>
        <el-form label-width="150px" :model="order" ref="orderForm" style="margin-top: 30px;font-weight: 600;min-width: 500px;margin-right: 30px;" :rules="rules">
          <el-form-item label="My Address" style="margin-bottom: 20px;" prop="My Address" v-if="!islogin">
            <el-select v-model="addressId"  placeholder="Select Address" size="large" style="width: 500px;font-size: 16px;" @change="changeAddress">
              <el-option
                v-for="item in myAddress"
                :key="item.id"
                :label="item.address"
                :value="item.id"
              >
              <span style="float: left">{{ item.address }}</span>
              <span
                style="
                  float: right;
                  color: var(--el-text-color-secondary);
                  font-size: 13px;
                "
                >{{ item.firstName }} {{ item.lastName }}</span
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="Email" style="margin-bottom: 20px;" prop="email">
            <el-input v-model="order.email" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter your email address"></el-input>
          </el-form-item>
          <el-form-item label="First Name" style="margin-bottom: 20px;" prop="firstName">
            <el-input v-model="order.firstName" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter your first name"></el-input>
          </el-form-item>
          <el-form-item label="Last Name" style="margin-bottom: 20px;" prop="lastName">
            <el-input v-model="order.lastName" type="text" style="height: 40px;font-size: 16px;"  placeholder="Please enter your last name"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number" style="margin-bottom: 20px;" prop="phone">
            <el-input v-model="order.phone" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter your phone number" maxlength="15" ></el-input>
          </el-form-item>
          
          <el-form-item label="Country" style="margin-bottom: 20px;" prop="country">
            <el-select v-model="order.country"  placeholder="Select Country" size="large" style="width: 300px;font-size: 16px;" @change="setStateProvinceByCountry(order.country)" filterable>
              <el-option
                v-for="item in countryOptions"
                :key="item.code"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          
          <el-form-item label="State/Province" style="margin-bottom: 20px;" prop="province">
            <el-select v-if="stateOptions.length > 0" v-model="order.province"  placeholder="Select state" size="large" style="width: 300px;font-size: 16px;" @change="calcPrice" filterable>
              <el-option
                v-for="item in stateOptions"
                :key="item.code"
                :label="item.name"
                :value="item.name"
              />
            
            </el-select>
            <el-input v-else v-model="order.province" type="text"  style="height: 40px;font-size: 16px;"    placeholder="Please enter the state" @blur="calcPrice"></el-input>
          </el-form-item>
          <el-form-item label="City" style="margin-bottom: 20px;" prop="city">
            <el-input v-model="order.city" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter the city" @blur="calcPrice"></el-input>
          </el-form-item>
          <el-form-item label="Street Address" style="margin-bottom: 20px;" prop="address">
            <el-input v-model="order.address" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter the address" @blur="calcPrice"></el-input>
          </el-form-item>
          <el-form-item label="Zip/Postal Code" style="margin-bottom: 20px;" prop="postalCode">
            <el-input v-model="order.postalCode" type="text" style="height: 40px;font-size: 16px;"  placeholder="Please enter your postal code" @blur="calcPrice"></el-input>
          </el-form-item>
          
          <el-form-item label="School Name" style="margin-bottom: 20px;" prop="shoolName">
            <el-input v-model="order.schoolName" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter the name of the school" ></el-input>
          </el-form-item>
          <el-form-item label="Comments" style="margin-bottom: 20px;" prop="note">
            <el-input v-model="order.message" type="textarea" :rows="2" maxlength="1000" style="font-size: 16px;" show-word-limit  placeholder="Please enter a note" ></el-input>
          </el-form-item>

          <el-form-item v-if="getAccessToken"  label="Save Address" style="margin-bottom: 20px;" prop="save">
            <el-switch
              v-model="isSaveAddress"
              size="large"
              active-text="Yes"
              inactive-text="No"
              active-value="1"
              inactive-value="0"
            />
          </el-form-item>
        </el-form>

        <div>
         
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card title="mybag" style="width: 500px;">
        <template #header>
          <div style="display: flex;font-size: 24px;font-weight: 600;">
            <span>Order Summary</span>
            
          </div>
        </template>
        <!-- <span style="font-size: 32px;font-weight: 600;margin-bottom: 30px;">商品信息</span> -->
        <el-scrollbar max-height="320px">
          <div style="border-bottom: #e5e6e9 1px solid;padding: 10px;margin-bottom: 10px;display:flex" v-for="item in products" :key="item">
            <div  style="margin-right: 20px;">
              <el-image :src="item.imgs.split(',')[0]" style="width: 100px;height: 100px;"></el-image>
            </div>

            <div style="font-size: 14px;color: #141517;font-weight: 600;text-align: left;">
              <div style="width: 150px;">{{item.description}}</div>
              <div style="height: 20px;"><el-input-number v-model="item.num" :min="1" :max="item.inventory"  width="30px" @change="changeNum(item)" /></div>
              <div style="margin-top: 15px;">
							<!-- <template  v-for="spec in item.specDesc" :key="spec.key"> -->
								<span style="color: #969697;font-weight: 500;"> {{ setSpec(item.specDesc) }} </span>
							<!-- </template> -->
						</div>
              <div style="height: 30px;margin-top: 10px;">${{(item.price/100).toFixed(2)}}</div>
            </div>

            <div style="margin-top: 30px;margin-left: 100px;">
              <el-button type="text" @click="removeProduct(item)" style="width: 20px;">
                <el-icon size="20" style="vertical-align: middle">
                  <Close />
                </el-icon>
              </el-button>
            </div>
          </div>
        </el-scrollbar>
        <div>
          <div style="margin-top: 20px;text-align: right;">
            <div style="margin-bottom: 30px;">
              <el-input v-model="order.coupon" type="text"  style="height: 50px;width: 320px;margin-right: 30px;" placeholder="Enter Coupon Code" ></el-input>
              <el-button type="primary" @click="calcPrice" style="height: 50px;width: 100px;">SUBMIT</el-button>
            </div>
            <div v-loading="priceLoadding">
              <div style="height: 30px;" ><span style="float: left;">Total: </span><span style="float: right;">${{total.totalPrice}}</span></div>
              
              <div style="height: 30px;" v-if="total.taxPrice>0"><span style="float: left;">Tax:</span> <span style="float: right;">${{total.taxPrice}}</span> </div>
              <div style="height: 30px;color: #db7474;" v-if="total.couponPrice>0"><span style="float: left;">Coupon:</span> <span style="float: right;">-${{total.couponPrice}}</span> </div>
              <div style="height: 30px;color: #db7474;" v-if="total.pointPrice>0"><span style="float: left;">Points:</span> <span style="float: right;">-${{total.pointPrice}}</span> </div>
              <div style="height: 30px;" v-if="total.deliveryPrice>0"><span style="float: left;">Ship:</span> <span style="float: right;">${{total.deliveryPrice}}</span> </div>
              <div style="height: 30px;" ><span style="float: left;">Pay:</span> <span style="float: right;">${{total.payPrice}}</span> </div>
              <div style="height: 30px;margin-bottom: 30px;"> <span style="float: right;">Earn points: <span style="font-size: 20px;color: #67C23A;">{{Math.floor(total.payPrice)}}</span></span> </div>
            </div>
            
          </div>

          <div style="height: 600px;" v-loading="payLoading">
            <span style="font-weight: 600;font-size: 18px;">Choose payment method</span>
            <!-- 银行卡 -->
            <div id="checkout-form" style="margin-top: 30px;width: 460px;">
              <!-- <div id="card-name-field-container"></div> -->
              <div id="card-number-field-container" style="width: 450px;"></div>
              <div style="display: flex;">
                <div id="card-expiry-field-container" style="width: 225px;"></div>
                <div id="card-cvv-field-container" style="width: 225px;"></div>
              </div>
              
              <el-button id="card-field-submit-button" style="width: 450px;height: 50px;" type="primary" @click.prevent="orderValidate">
                Pay Now with Credit Card
              </el-button> 
            </div>
            <!-- paypal -->
            <div id="paypal-button-container" style="width: 450px;height: 60px;font-size: 24px;margin-top: 20px;" ></div>
            <!-- 线下支付 -->
            <el-button style="width: 450px;height: 50px;margin-top: 10px;" type="success" @click="checkByMail">
              Check / Money Order
            </el-button> 
            <!-- 亚马逊支付 -->
            <div id="AmazonPayButton" ref="amazonPayButton" style="width: 450px;height: 70px;font-size: 24px;margin-top: 20px;"  @click="payWithAmazon"></div>  
            <!-- 谷歌支付 -->
            <div id="googlePayButton" style="width: 450px;height: 50px;font-size: 24px;" ></div>

            
          </div>
          
        </div>
      </el-card>
    </el-col>
      
    </el-row>

  <pc-footer></pc-footer>
</template>



<script >
import { ref } from 'vue'
import { QuestionFilled } from '@element-plus/icons-vue'
import { amazonpay,paypalpay,googlepay,cardpay,amazongpayUpdate,offlinepay } from "@/api/order/pay.js";
import { order,calculatePrice,findByOrderId } from "@/api/order/order.js";
import { queryAll } from "@/api/customer/customerAddress.js";
import { myPoints } from "@/api/customer/customerWallet.js";
import { my } from "@/api/customer/customer.js";
import { myAccount } from "@/api/customer/account.js";
import {useRouter, useRoute} from "vue-router"
import useCartCache from "@/store/cart";
import {getAccessToken} from "@/utils/auth";
import { getCountry,getStateProvince } from "@/api/base/index.js";
import {doAddCart, doDeleteCart, getCartAll,doUpdateCart} from "@/api/spu/cart"

import { doAdd } from "@/api/customerAddress/index.js";
// import { StripeGooglePay } from 'vue-stripe-elements-plus';
import _ from 'lodash';

export default {
  name: 'order',
  components: {
    QuestionFilled,
    // StripeGooglePay
  },
  props: {
    msg: String
  },
  data() {
    return {
      isSaveAddress:0,
      googlePayClient: null,
      environment: 'TEST', // 或 'PRODUCTION'
      router : useRouter(),
      cartCacheStore : useCartCache(),
      getAccessToken : getAccessToken(),
      products: [
        
      ],
      islogin:1,
      order: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        country: 'United States',
        province: '',
        address: '',
        postalCode: '',
        coupon: '',
        schoolName: '',
        message: '',
        payType: 4,
        currenncyCode:"USD",
      },
      countryOptions: [
       
      ],
      stateProvinceOptions:[],
      stateOptions:[],
      rules: {
        firstName: [
          { required: true, message: 'Please enter your first name', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Please enter your last name', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Please enter phone number', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'Please select a country', trigger: 'change'}
        ],
        province: [
          { required: true, message: 'Please enter state/province', trigger: 'blur'},
          { required: true, message: 'Please select state/province', trigger: 'change'}
        ],
        city: [
          { required: true, message: 'Please enter the city', trigger: 'blur'}
        ],
        address: [
          { required: true, message: 'Please enter the address', trigger: 'blur' }
        ],
        postalCode: [
          { required: true, message: 'Please enter your postal code', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please enter your email address', trigger: 'blur' }
        ],
      },
      
      amazonpayButton:null,
      publishableKey:'pk_live_51Pq5De0098gZMB2KqUplr6uIX1SH4iSMsW6SNztyNnLrnaerEXOhj52QFRJyDwALa4iOA8cSzZE0BKbGDaBhbcoQ00ZOlWmxCg',
      myAddress:[],
      addressId:null,
      orderAddress:null,
      total:{
        totalPrice:0,
        payPrice:0,
        deliveryPrice:0,
        pointPrice:0,
        taxPrice:0,
        couponPrice:0,
      },
      payLoading:false,
      priceLoadding:false,
    }
  },

  async created() {
    if(this.$route.query.orderId){
      const {data:url} = await amazongpayUpdate({orderId:this.$route.query.orderId});
      window.location.href= url.amazonPayRedirectUrl;
    }
    this.products = JSON.parse(localStorage.getItem('products'));
    if(this.products ==null ||  this.products.length == 0){
      this.$router.push({ path: '/'});
      return;
    }
    const {data:country} = await getCountry({});
    this.countryOptions = country;
    
    const {data:stateProvince} = await getStateProvince({});
    this.stateProvinceOptions = stateProvince;
    this.setStateProvinceByCountry(this.order.country)
  },
  methods: {
    async initAmazonPay() {
      this.amazonpayButton = await amazon.Pay.renderButton('#AmazonPayButton', {
        merchantId: 'A3C4QCITL0SIUC',
        publicKeyId: 'LIVE-AGSFSLMZZ32L5EORSS66FDR3', 
        ledgerCurrency: 'USD',          
        checkoutLanguage: 'en_US', 
        productType: 'PayOnly', 
        placement: 'Cart',
        buttonColor: 'Gold',
      });
      
      await this.setAmazonPayCSS();
    },
    async setAmazonPayCSS(){
      
      setTimeout(() => {
        var view3 =  this.$refs.amazonPayButton.shadowRoot.querySelectorAll('.amazonpay-button-view3');
        
        view3[0].style.display = 'none'
        var chevrons =  this.$refs.amazonPayButton.shadowRoot.querySelectorAll('.amazonpay-button-chevrons');
        chevrons[0].style.display = 'none'
        var view1 =  this.$refs.amazonPayButton.shadowRoot.querySelectorAll('.amazonpay-button-view1');
        view1[0].style.background = '#ffc439'
        view1[0].style.border = '0px solid #ffc439'
      }, 2000); // 1000毫秒即1秒
    },
    async payWithAmazon(){
      try{
        await this.$refs['orderForm'].validate();
     
        this.order.orderProductReqDto = this.products;
        this.order.payType = 7;
        const {data:orderData} = await order(this.order);
        
        this.removeCart();
        this.saveAddress();
        this.amazonpayButton.initCheckout({
          
          createCheckoutSession: function(){
              return new Promise((resolve, reject) => {
                        setTimeout(() => {

                          amazonpay({orderId:orderData.id,returnUrl:window.location.origin+'/pages/orderRedirect'}).then(data =>{
                            resolve(data.data.checkoutSessionId);
                          })
                          
                        }, 0); 
                      });
          }
        });
      } catch (err) {
        // 验证失败，err 是包含错误信息的对象
        console.error('表单验证失败：', err);
        return;
      }  
    },

    async getMy(){

      const {data:accounts} = await myAccount({});
      this.order.email = accounts[0].accountValue;

      const {data:address} = await queryAll({});
      this.myAddress = address;
      address.some((item,index)=>{
        if(item.isDefault){
          this.addressId = item.id;
          this.order.firstName = item.firstName;
          this.order.lastName = item.lastName;
          this.order.phone = item.phone;
          this.order.address = item.address;
          this.order.city = item.city;
         
          this.order.postalCode = item.postalCode;
          this.order.country = item.country;
          this.order.schoolName = item.schoolName;
          // this.calcPrice();
          this.setStateProvinceByCountry(item.country)
          this.order.province = item.province;
          return;
        }
      })
      
      const {data:customer} = await my({});
      debugger
      if(!this.order.firstName){
        this.order.firstName = customer.firstName;
      }
      if(!this.order.lastName){
        this.order.lastName = customer.lastName;
      }
      if(!this.order.phone){
        this.order.phone = customer.phone;
      }
    },
    changeAddress() {
      this.myAddress.some((item,index)=>{
        if(item.id == this.addressId){
          this.order.firstName = item.firstName;
          this.order.lastName = item.lastName;
          this.order.phone = item.phone;
          this.order.address = item.address;
          this.order.city = item.city;
         
          this.order.postalCode = item.postalCode;
          this.order.country = item.country;
          this.order.schoolName = item.schoolName;
          this.setStateProvinceByCountry(item.country)
          this.order.province = item.province;
          return;
        }
      })
      this.calcPrice();
    },
    changeNum(value) {
      if(getAccessToken()){
        var param = {
          productSpecId:value.productSpecId,
          num:value.num,
          id:value.id,
          checked:value.checked?1:0,
          productId:value.productId
        }    
        doUpdateCart(param);
      }else{
        var cartList = JSON.parse(localStorage.getItem("cartList"))
        cartList.map(item => {
          if(item.productSpecId == value.productSpecId){
            item.num = value.num;
            
          }
        })
        localStorage.setItem("cartList",JSON.stringify(cartList));
      }
      
      this.cartCacheStore.initCart();
      this.calcPrice();
      
    },
    calcPrice: _.throttle(async function(){
      
      this.priceLoadding = true;
      this.order.orderProductReqDto = this.products;
      this.order.points = 0;
      if(getAccessToken()){
        try{
          const {data:points} = await myPoints({})
          if(points){
            this.order.points = points.walletValue;
          }
        }catch (err) {
          // 验证失败，err 是包含错误信息的对象
          console.error('error', err);
        }
        
      }
      
      const {data:total} = await calculatePrice(this.order);
      this.total.totalPrice = (total.totalPrice/100).toFixed(2);
      this.total.taxPrice = (total.taxPrice/100).toFixed(2);
      this.total.couponPrice = (total.couponPrice/100).toFixed(2);
      this.total.pointPrice = (total.pointPrice/100).toFixed(2);
      this.total.payPrice = (total.payPrice/100).toFixed(2);
      this.total.deliveryPrice = (total.deliveryPrice/100).toFixed(2);
      try{
        this.initGooglepay();
      }catch (err) {
        // 验证失败，err 是包含错误信息的对象
        console.error('google error', err);
      }
      
      this.priceLoadding = false;
    },1000),
    async checkByMail(){
      try{
        await this.$refs.orderForm.validate();
        this.payLoading = true;
        this.order.orderProductReqDto = this.products;
        this.order.points = 0;
        this.order.payType = 8;
        const {data:orderData} = await order(this.order);
        this.removeCart();
        this.saveAddress();
        offlinepay({orderId:orderData.id});
        this.$alert(`Your order # is: ${orderData.id}.<br/>
        <br/>
          We'll email you an order confirmation with details. If you don't see this email in your inbox within 10 minutes, look for it in your junk mail folder. If you find it there, please mark the email as "Not Junk".<br/>
          <br/>
          Payment Method:<br/>
          Check / Money order<br/>
          <br/>
          Make Check payable to:<br/>
          Adena International Trading LLC<br/>
          <br/>
          Send Check to:<br/>
          1376 Holly Ave.<br/>
          Columbus, OH 43212`, 'Thanks for your order', {
            dangerouslyUseHTMLString: true,
              confirmButtonText: 'Confirm',
              callback: action => {
                  if (getAccessToken()) {
                      this.$router.push({ path: '/pages/my', query: { type: "order" } });
                  } else {
                      this.$router.push({ path: '/pages/orderQuery', query: { orderId: orderData.id } });
                  }
              }
          });
        //判断是否登录，登录则跳转到订单详情页，未登录则跳转到订单查询页
        
            // setTimeout(() => {
            //   if(getAccessToken()){
            //     this.$router.push({ path: '/pages/my', query:{type:"order"} });
            //   }else{
            //     this.$router.push({path:'/pages/orderQuery',query:{orderId:orderData.id}});
            //   }
            // }, 3000);
      
      }catch (err) {
        // 验证失败，err 是包含错误信息的对象
        console.error('表单验证失败：', err);
        return;
      }
      
    },
    removeProduct(value){
      this.products.some((item,index)=>{
        if(item.productSpecId===value.productSpecId){
          this.products.splice(index,1);
          localStorage.setItem('products',JSON.stringify(this.products))
        }
      
      })
      this.calcPrice();
    },
    initPaypal(){
      paypal.Buttons({
        style: {
          layout: 'vertical',
          color:  'gold',
          // shape:  'rect',
          label:  'paypal',
        },
        createOrder:  async (data, actions) => {
          try{
            await this.$refs['orderForm'].validate();
            this.payLoading = true;
            this.order.payType = 5;
            return order(this.order).then((data) =>{
              this.removeCart();
              this.saveAddress();
              this.order.id = data.data.id;
              var ret2 = paypalpay({orderId:data.data.id}).then((data) => data.data.payId);
              return ret2;
            });
          }catch (err) {
            // 验证失败，err 是包含错误信息的对象
            console.error('表单验证失败：', err);
            return;
          }
         

        },
        onApprove: (data, actions) => {
           //判断是否登录，登录则跳转到订单详情页，未登录则跳转到订单查询页
           
            // setTimeout(() => {
            //   if(getAccessToken()){
            //     this.$router.push({ path: '/pages/my', query:{type:"order"} });
            //   }else{
            //     this.$router.push({path:'/pages/orderQuery',query:{orderId:this.order.id}});
            //   }
            // }, 3000);
            this.checkPay(this.order.id,this.order.payType);
        },
        onCancel:(data, actions) => {
           //判断是否登录，登录则跳转到订单详情页，未登录则跳转到订单查询页
           this.payLoading = true;
            setTimeout(() => {
              if(getAccessToken()){
                this.$router.push({ path: '/pages/my', query:{type:"order"} });
              }else{
                this.$router.push({path:'/pages/orderQuery',query:{orderId:this.order.id}});
              }
            }, 1000);
        },
        onError:  (error) => {
           //判断是否登录，登录则跳转到订单详情页，未登录则跳转到订单查询页
           if(!this.order.id){
            return;
           }
           this.payLoading = true;
            setTimeout(() => {
              if(getAccessToken()){
                this.$router.push({ path: '/pages/my', query:{type:"order"} });
              }else{
                this.$router.push({path:'/pages/orderQuery',query:{orderId:this.order.id}});
              }
            }, 1000);
        },
      }).render('#paypal-button-container');
    },
    async initCardFields(){
      const cardField =  paypal.CardFields({
        createOrder:  (data) => {
            this.order.payType = 4;
            this.payLoading = true;
            return order(this.order).then((data) =>{
              this.removeCart();
              this.saveAddress();
              this.order.id = data.data.id;
              var ret2 = cardpay({orderId:data.data.id}).then((data) => data.data.payId);
              return ret2;
            });

        },
        onApprove:  (data) => {
            
            // setTimeout(() => {
            //   if(getAccessToken()){
            //     this.$router.push({ path: '/pages/my', query:{type:"order"} });
            //   }else{
            //     this.$router.push({path:'/pages/orderQuery',query:{orderId:this.order.id}});
            //   }
            // }, 3000);
           //判断是否登录，登录则跳转到订单详情页，未登录则跳转到订单查询页
          this.checkPay(this.order.id,this.order.payType);
        },
        onCancel:(data, actions) => {
           //判断是否登录，登录则跳转到订单详情页，未登录则跳转到订单查询页
           this.payLoading = true;
            setTimeout(() => {
              if(getAccessToken()){
                this.$router.push({ path: '/pages/my', query:{type:"order"} });
              }else{
                this.$router.push({path:'/pages/orderQuery',query:{orderId:this.order.id}});
              }
            }, 1000);
        },
        onError:  (error) => {
           //判断是否登录，登录则跳转到订单详情页，未登录则跳转到订单查询页
           console.error('信用卡支付失败', error);
           this.payLoading = true;
            setTimeout(() => {
              if(getAccessToken()){
                this.$router.push({ path: '/pages/my', query:{type:"order"} });
              }else{
                this.$router.push({path:'/pages/orderQuery',query:{orderId:this.order.id}});
              }
            }, 1000);
        },
      });

      
      if (cardField.isEligible()) {

        const numberField = cardField.NumberField();
        console.log("numberField:",numberField);
        numberField.render("#card-number-field-container");

        const cvvField = cardField.CVVField();
        cvvField.render("#card-cvv-field-container");

        const expiryField = cardField.ExpiryField();
        expiryField.render("#card-expiry-field-container");

        document
          .getElementById("card-field-submit-button")
          .addEventListener("click", async (data) => {
            try{
              await this.$refs['orderForm'].validate();
            }catch(err){
              console.error('表单验证失败：', err);
              return;
            }
              cardField.submit().then(() => {
                // submit successful
              });
           
            
            
          });
      }
    },
    async initGooglepay(){
            
      const options = {
        mode: 'payment',
        amount: parseInt(this.total.payPrice*100),
        currency: 'usd',
        // Customizable with appearance API.
        appearance: {/*...*/},
      };
      

      const stripe = Stripe(this.publishableKey);
      const elements = stripe.elements(options);

      const expressCheckoutElement = elements.create('expressCheckout',{
        wallets:{
          googlePay:'always'
        }
      });
      expressCheckoutElement.mount('#googlePayButton');

      expressCheckoutElement.on('click', async (event) => {
        // Handle click event
        
        try{
           await this.$refs['orderForm'].validate();
          event.resolve()
        }catch(err){
          console.error('表单验证失败：', err);
          //return;
        }
        
      });

      expressCheckoutElement.on('confirm', async (event) => {
        
          this.order.payType = 6;
          const {data:orderData} = await order(this.order)
          this.removeCart();
          this.saveAddress();
          const {data:{payId}} = await  googlepay({orderId:orderData.id})

          // const type = "/pages/orderQuery?orderId="+orderData.id
          // if(getAccessToken()){
          //   type = '/pages/my?type=order'
          // }
          stripe.confirmPayment({
            elements,
            clientSecret:payId,
            confirmParams: {
              return_url: window.location.origin+'/pages/orderRedirect?orderId='+orderData.id,
            },
          });
            
          
        
      })
      
    },
    async orderValidate(){
      try{
        await this.$refs['orderForm'].validate();
      }catch(err){
        console.error('表单验证失败：', err);
        return;
      }
      
     
    },
    removeCart(){
      this.products.forEach((item)=>{
        this.cartCacheStore.removeCartItem(item)
      })
      
    },
    setSpec(item) {
      if(item){
       
        let str = '';
        while(typeof item == 'string'){
          item = JSON.parse(item)
        }
        
        item.forEach((value) => {
            str += value.key + ':' + value.value + '; '
          })
       
        return str;
      }
      
    },
    setStateProvinceByCountry(countryId){
      if(countryId){
        this.order.province = null
        const country = this.countryOptions.filter((item)=>{
          return item.name == countryId
        })
        this.stateOptions = this.stateProvinceOptions.filter((item)=>{
          return item.country == country[0].code
        })
      }
    },
    async checkPay(item,payType){
      let intervalId;
      let count = 0;

      // 启动定时器，每隔1000毫秒执行一次
      intervalId = setInterval(async () => {
        count++;
        
        const order = await findByOrderId({ orderId: item });
        
        if (order.data.status == 2 || (count > 60 && payType == 4)) {
          // ElMessage({
          //   message: "Payment successful",
          //   type: "success"
          // });
          

          // 当订单状态变为2时，清除定时器，避免后续无谓的请求
          clearInterval(intervalId);

          if(getAccessToken()){
            this.$router.push({ path: '/pages/my', query:{type:"order"} });
          }else{
            this.$router.push({path:'/pages/orderQuery',query:{orderId:this.order.id}});
          }
        }
      }, 1000);
      
    },
    async saveAddress(){
      if(this.isSaveAddress =='1' && getAccessToken()){
        await doAdd(this.order);
      }
    },
  },
  async mounted() {
    if(getAccessToken()){
      try{
        await this.getMy();
        this.islogin = 0
      }catch(e){
        this.islogin = 1
      }
     
      
    }
    
    this.payLoading = true;
    await this.calcPrice();
    
    await this.initCardFields();
    await this.initPaypal();
     
    await this.initAmazonPay();
    this.payLoading = false;

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .el-popper.is-dark2 {
  	background: #e35959;
    border: 1px solid #e35959;
    margin-left: 10px;
    vertical-align: middle;
    width: 22px;
    height: 12px;
  }
  .el-popper.is-dark2 .el-popper__arrow::before{
    background: #e35959;
    border: 1px solid #e35959;
  }
  .el-popover.el-popover.cc{
    padding: 0;
  }
  .el-tabs__item{
    font-size:24px !important;
    }
    #tab-first{
      margin-bottom: 10px;
    }




    
</style>
