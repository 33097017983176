<template>
	<pc-header/>
	
	<!-- 轮播图	-->
	<el-carousel class="main_carousel_container" height="800px" >
		<el-carousel-item v-for="item in carousels" :key="item">
			<el-image :src="item.path" fit="fill" style="height: 800px;width: 100%;"></el-image>
			
		</el-carousel-item>
	</el-carousel>
	<!-- <splide ref="splide" type="loop" class="splide">
       <splide-slide v-for="(item, index) in carousels" :key="index">
         <el-image :src="item.src" alt="Image Slide" fit="fill" style="height: 800px;width: 100%;"></el-image>
       </splide-slide>
     </splide> -->
	
	<!-- 分类导航	-->
	<div style="width:1400px;margin: 80px auto">
		<el-row :gutter="30" style="margin:auto">
			<el-col :span="4" style="margin-top:50px;" v-for="item in categoryList" :key="item.id">
				<router-link :to="'/pages/productList?categoryId=' + item.id ">
					<!-- <el-card shadow="hover" > -->
						<div style="text-align: center;">
							<el-avatar  :src="item.imgPC" style="width: 130px;height: 130px;" fit="fill"></el-avatar>
						</div>
						<div style="margin-top:10px;text-align: center;">
							<span style="font-weight: bold;color:#000;font-size: 18px;">{{item.name}}</span>
						</div>
					<!-- </el-card> -->
				</router-link>
			</el-col>
		</el-row>
		
		
	</div>
	
	<!-- 热门商品	-->
	<div class="new-product-container main-content-container" style="width: 1400px;margin: 180px auto">
		<el-text class="mx-1" tag="b" style="font-size: 30px;margin-left: 100px;">Best Sellers</el-text>
		<router-link to="/pages/productList?sort=bestSalers">
			<el-link class="mx-1" tag="b" style="font-size: 20px;margin-left: 980px;color: rgb(191, 182, 182);">more</el-link>
		</router-link>
		<el-carousel height="450px" style="margin: 30px 0;" arrow="always">
			
			<el-carousel-item v-for="(productList, index) in hotList" :key="index" arrow="always">
				<el-row :gutter="30" style="margin: 0px 50px;">
					
					
					<el-col :span="6" height="420px" v-for="item in productList" :key="item.id">
						<router-link :to="'/pages/productDetail?productId=' + item.id">
							<el-card shadow="hover" style="height: 400px;border-radius: 10px;">
								<div style="margin: 20px auto;">
								<el-image style="width: 100%; height: 250px" :src='getDefaultImgUrl(item.imgs)' fit="fill"></el-image>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.name }}</el-text>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.description }}</el-text>
								</div>
								
								<div style="text-align: center;">
									<el-text class="mx-1" size="large">${{ floatToFixed2(item.price) }}</el-text>
									<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
									${{ floatToFixed2(item.oprice) }}
								</el-text>
								</div>
								
							</el-card>

						</router-link>
					</el-col>
				</el-row>
			</el-carousel-item>
		</el-carousel>
	</div>
	
	<!-- 评论	-->
	<div style="background: linear-gradient(to bottom, #a0cfff, #c6e2ff);margin-top:30px;">
		<el-carousel height="400px" width="500px" style="margin: auto;width: 1200px;" arrow="never">
			
			<el-carousel-item v-for="item in comments" :key="item" style="height: 400px;width: 1200px;">
				<div style="width: 600px;margin: auto;">
					<div style="text-align: center;margin-top: 50px;">
						<el-text class="mx-1" tag="b" style="font-size: 30px;">Reviews</el-text>
					</div>
					
					<el-text class="mx-1" tag="b" style="font-size: 20px;height: 150px;margin-top: 50px;" line-clamp="3">{{ item.content }}</el-text>
					
					<div style="text-align: center;margin-top: 10px;">
						<el-avatar :size="50" :src="customerImg" style="vertical-align: middle;margin-right: 20px;"/>
						<el-text class="mx-1" tag="b" style="font-size: 20px;">{{ item.firstName + ' ' + item.lastName }}</el-text>
					</div>
					
				</div>
				
			</el-carousel-item>
		
		</el-carousel>
	</div>
	
	<!-- 新品上市	-->
	<div style="width: 1400px;margin: 180px auto">
		<!-- <el-text class="mx-1" tag="b" style="font-size: 30px;margin-left: 100px;">新品上市</el-text> -->
		<!-- <router-link to="/products?categoryId=12">
			<el-link class="mx-1" tag="b" style="font-size: 20px;margin-left: 1000px;color: rgb(191, 182, 182);">more</el-link>
		</router-link> -->

		<div style="display: flex;">
			<router-link to="/pages/productList?sort=newArrivals">
				<el-card style="background: linear-gradient(to right, #d9ecff, #79bbff);display: flex;width: 680px;margin-right:30px;height: 400px;" >
					<div style="display: flex;padding: 30px;">
						<span style="font-size: 50px;font-weight: bold;width: 380px;text-align: center;margin-top: 100px;">
							New Arrivals
						</span>
						<div style="margin-top:40px" v-if="newArrivalsList.length > 0">
							<router-link :to="'/pages/productDetail?productId=' +newArrivalsList[6].id">
								<el-image 
								class="productImg"
								:src="newArrivalsList[6].imgs.split(',')[0]" 
								style="width: 100px;height: 100px;border-radius: 10px;margin-right:-80px"></el-image>
							</router-link>
							

							<router-link :to="'/pages/productDetail?productId=' +newArrivalsList[7].id">
								<el-image 
								class="productImg"
								:src="newArrivalsList[7].imgs.split(',')[0]" 
								style="width: 100px;height: 100px;border-radius: 10px;margin-top:10px"></el-image>
							</router-link>
						</div>
						<div style="margin-top:40px" v-if="newArrivalsList.length > 0">
							<router-link :to="'/pages/productDetail?productId=' +newArrivalsList[8].id">
								<el-image 
								class="productImg"
								:src="newArrivalsList[8].imgs.split(',')[0]" 
								style="width: 100px;height: 100px;border-radius: 10px;margin-right:-80px"></el-image>
							</router-link>

							<router-link :to="'/pages/productDetail?productId=' +newArrivalsList[9].id">
								<el-image 
								class="productImg"
								:src="newArrivalsList[9].imgs.split(',')[0]" 
								style="width: 100px;height: 100px;border-radius: 10px;margin-top:10px"></el-image>
							</router-link>
						</div>
						
					</div>
				</el-card>
			</router-link>
			
			<router-link :to="'/pages/productDetail?productId=' +newArrivalsList[0].id" v-if="newArrivalsList.length > 0">
				<el-card shadow="hover" style="height: 400px;width: 300px;margin-right:50px">
					<div style="text-align: center;margin: 20px auto;">
					<el-image style="width: 250px; height: 250px;" :src='getDefaultImgUrl(newArrivalsList[0].imgs)' fit="fill"></el-image>
					</div>
					<div style="text-align: center;font-size: 14px;font-weight: 600;">
						<el-text class="mx-1" size="large" >{{ newArrivalsList[0].name }}</el-text>
					</div>
					<div style="text-align: center;font-size: 14px;font-weight: 600;">
						<el-text class="mx-1" size="large" >{{ newArrivalsList[0].description }}</el-text>
					</div>
					<div style="text-align: center;">
						<el-text class="mx-1" size="large">${{ floatToFixed2(newArrivalsList[0].price) }}</el-text>
						<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
							${{ floatToFixed2(newArrivalsList[0].oprice) }}
						</el-text>
					</div>
					
				</el-card>
				
			</router-link>
			<router-link :to="'/pages/productDetail?productId=' +newArrivalsList[1].id" v-if="newArrivalsList.length > 0">
				<el-card shadow="hover" style="height: 400px;width: 300px;">
					<div style="margin: 20px auto;text-align: center;">
					<el-image style="width: 250px; height: 250px;" :src='getDefaultImgUrl(newArrivalsList[1].imgs)' fit="fill"></el-image>
					</div>
					<div style="text-align: center;font-size: 14px;font-weight: 600;">
						<el-text class="mx-1" size="large" >{{ newArrivalsList[1].name }}</el-text>
					</div>
					<div style="text-align: center;font-size: 14px;font-weight: 600;">
						<el-text class="mx-1" size="large" >{{ newArrivalsList[1].description }}</el-text>
					</div>
					<div style="text-align: center;">
						<el-text class="mx-1" size="large">${{ floatToFixed2(newArrivalsList[1].price) }}</el-text>
						<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
							${{ floatToFixed2(newArrivalsList[1].oprice) }}
						</el-text>
					</div>
					
				</el-card>
				
			</router-link>
		</div>
		<div style="display: flex;margin-left: 10px;margin-top:30px">
			<template v-for="item in 4" v-if="newArrivalsList.length > 0">
				<router-link :to="'/pages/productDetail?productId=' + newArrivalsList[item+1].id">
					<el-card shadow="hover" style="height: 400px;width: 300px;margin-right:50px">
						<div style="margin: 20px auto;text-align: center;">
						<el-image style="width: 250px; height: 250px;" :src='getDefaultImgUrl(newArrivalsList[item+1].imgs)' fit="fill"></el-image>
						</div>
						<div style="text-align: center;font-size: 14px;font-weight: 600;">
							<el-text class="mx-1" size="large" >{{ newArrivalsList[item+1].name }}</el-text>
						</div>
						<div style="text-align: center;font-size: 14px;font-weight: 600;">
							<el-text class="mx-1" size="large" >{{ newArrivalsList[item+1].description }}</el-text>
						</div>
						<div style="text-align: center;">
							<el-text class="mx-1" size="large">${{ floatToFixed2(newArrivalsList[item+1].price) }}</el-text>
							<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
								${{ floatToFixed2(newArrivalsList[item+1].oprice) }}
							</el-text>
						</div>
						
					</el-card>
					
				</router-link>
			</template>
		</div>
	
	</div>
	
	<!-- 猜你喜欢	-->
	<div style="width: 1400px;margin: 180px auto">
		<el-text class="mx-1" tag="b" style="font-size: 30px;margin-left: 100px;">Featured items you may like</el-text>
		<router-link to="/pages/productList?sort=mostPopular">
			<el-link class="mx-1" tag="b" style="font-size: 20px;margin-left: 730px;color: rgb(191, 182, 182);">more</el-link>
		</router-link>
		<el-carousel height="450px" style="margin: 30px 0;" arrow="always">
			
			<el-carousel-item v-for="(productList, index) in sortList" :key="index" arrow="always">
				<el-row :gutter="30" style="margin: 0px 50px;">
					
					
					<el-col :span="6" height="420px" v-for="item in productList" :key="item.id">
						<router-link :to="'/pages/productDetail?productId=' + item.id">
							<el-card shadow="hover" style="height: 400px;">
								<div style="margin: 20px auto;">
								<el-image style="width: 100%; height: 250px" :src='getDefaultImgUrl(item.imgs)' fit="fill"></el-image>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.name }}</el-text>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.description }}</el-text>
								</div>
								<div style="text-align: center;">
									<el-text class="mx-1" size="large">${{ floatToFixed2(item.price) }}</el-text>
									<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
										${{ floatToFixed2(item.oprice) }}
									</el-text>
								</div>
								
							</el-card>
							
						</router-link>
					</el-col>
				</el-row>
			</el-carousel-item>
		</el-carousel>
	</div>

	<!-- 低于25美元	-->
	<div style="width: 1400px;margin: 180px auto">
		<el-text class="mx-1" tag="b" style="font-size: 30px;margin-left: 100px;">Deals Under $25</el-text>
		<router-link to="/pages/productList?sort=mostPopular">
			<el-link class="mx-1" tag="b" style="font-size: 20px;margin-left: 900px;color: rgb(191, 182, 182);">more</el-link>
		</router-link>
		<el-carousel height="450px" style="margin: 30px 0;" arrow="always">
			
			<el-carousel-item v-for="(productList, index) in underList" :key="index" arrow="always">
				<el-row :gutter="30" style="margin: 0px 50px;">
					
					
					<el-col :span="6" height="420px" v-for="item in productList" :key="item.id">
						<router-link :to="'/pages/productDetail?productId=' + item.id">
							<el-card shadow="hover" style="height: 400px;">
								<div style="margin: 20px auto;">
								<el-image style="width: 100%; height: 250px" :src='getDefaultImgUrl(item.imgs)' fit="fill"></el-image>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.name }}</el-text>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.description }}</el-text>
								</div>
								<div style="text-align: center;">
									<el-text class="mx-1" size="large">${{ floatToFixed2(item.price) }}</el-text>
									<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
										${{ floatToFixed2(item.oprice) }}
									</el-text>
								</div>
								
							</el-card>
							
						</router-link>
					</el-col>
				</el-row>
			</el-carousel-item>
		</el-carousel>
	</div>
	<pc-footer/>
</template>

<script>
import {getDefaultImgUrl} from "@/utils/imageUtil"
import {fenToYuan, floatToFixed2} from "@/utils/priceUtil";
import {getAgeList, getCategoryList, getProductAllByPage, getProductLabelAll, getSpuAll} from "@/api/spu"
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import {getAllByPage} from "@/api/carousel";
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
// import '@splidejs/vue-splide/css';
import { getEvaluateByPage} from "@/api/spu/evaluate"



export default {
	name: 'index',
	// components: { Splide, SplideSlide },
	data() {
		return {
			searchText: "",
			customerImg: require('@/assets/img/customer.png'),
			
			carousels: [
				
			
			],
			
			comments: [
				// {
				// 	avatar: require("@/assets/img/1.jpg"),
				// 	desc: 'comment 1comment 1comment 1comment 1comment 1comment 1comment 1comment 1comment 1comment 1',
				// 	name: 'your name'
				// },
				// {
				// 	avatar: require("@/assets/img/1.jpg"),
				// 	desc: 'comment 2',
				// 	name: 'your name2'
				// },
			],
			email: "",
			productList: [],
			categoryList:[],
			newArrivalsList:[],
			hotList:[],
			sortList:[],
			underList:[],
		}
	},
	created() {
		this.getCarousels();
		this.getCategoryList();
		this.getNewArrivals();
		this.getHot();
		this.getSort();
		this.getUnder();
		this.getEvaluate();
	},
	methods: {
		floatToFixed2, fenToYuan, getDefaultImgUrl,
		// 用于处理数组，按照指定的格式进行分割
		resolveList(array, splitGap = 4) {
			const length = array && array.length > 0 ? array.length : 0;
			const returnArray = [];
			let cacheList = [];
			
			// 如果数组为空直接返回空数组
			if (length === 0) return returnArray;
			
			array.forEach((item, index) => {
				const remainderNum = index % splitGap;
				if (remainderNum === 0 && index !== 0) {
					returnArray.push(cacheList)
					cacheList = []
				}
				cacheList.push(item);
			})
			returnArray.push(cacheList)
			console.log("returnArray",returnArray)
			return returnArray
		},
		async getCategoryList() {
			const {data: {list}} = await getCategoryList({
				page: 1,
				pageSize: 12,
				conditions: [queryWrapper("sort", QueryCondition.DESC, "")]
			})
			this.categoryList = list
		},
		async getNewArrivals(){
			const {data: {list: productList}} = await getProductAllByPage({
				page: 1,
				pageSize: 10,
				conditions: [queryWrapper("create_time", QueryCondition.DESC, [])]
			});
			this.newArrivalsList = productList
		},
		async getHot(){
			const {data: {list: productList}} = await getProductAllByPage({
				page: 1,
				pageSize: 12,
				conditions: [queryWrapper("sold_num", QueryCondition.DESC, [])]
			});
			this.hotList = this.resolveList(productList, 4);
		},
		async getSort(){
			const {data: {list: productList}} = await getProductAllByPage({
				page: 1,
				pageSize: 12,
				conditions: [queryWrapper("sort", QueryCondition.DESC, [])]
			});
			this.sortList = this.resolveList(productList, 4);
			 
		},
		async getUnder(){
			const {data: {list: productList}} = await getProductAllByPage({
				page: 1,
				pageSize: 12,
				conditions: [queryWrapper("price", QueryCondition.LT, 2500)]
			});
			this.underList = this.resolveList(productList, 4);
			 
		},
		async getCarousels() {
			const {data: {list}} = await getAllByPage({
				page: 1,
				pageSize: 12,
				conditions: []
			})
			this.carousels = list
		},
		async getEvaluate() {
			const {data: {list}} = await getEvaluateByPage({
				page: 1,
				pageSize: 5,
				conditions: [queryWrapper("level", QueryCondition.DESC, []),queryWrapper("status", QueryCondition.EQ, 2)]
			})
			this.comments = list
		},
	},
	async mounted() {
		
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.category_nav {
	/* display: flex; */
	justify-content: space-around;
	flex-wrap: wrap
}

.el-popper.is-dark2 {
	background: #e35959;
	border: 1px solid #e35959;
	margin-left: 10px;
	vertical-align: middle;
	width: 22px;
	height: 12px;
}

.el-popper.is-dark2 .el-popper__arrow::before {
	background: #e35959;
	border: 1px solid #e35959;
}

.el-carousel__arrow.main_carousel_container {
	font-size: 30px;
	border-radius: 0;
	height: 100px;
	width: 50px;
}

.productImg:hover {
	transform: scale(1.1);
}

.productImg {
	transition: transform 0.3s ease-in-out;
	
}



.main_carousel_container :deep(.el-carousel__arrow--left) {
	font-size: 32px;
    background: linear-gradient(to bottom, #79bbff, #337ecc);
    border-radius: 5px;
    height: 150px;
    width: 50px;
 }

.main_carousel_container :deep(.el-carousel__arrow--right) {
	font-size: 32px;
    background: linear-gradient(to bottom, #79bbff, #337ecc);
    border-radius: 5px;
    height: 150px;
    width: 50px;
 }
</style>
