<template>
	
	<pc-header></pc-header>
	<!--	<div>当前查询参数：{{ JSON.stringify(route.query) }}</div>-->
		<!-- <div style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;"> -->
			<!-- <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">Today's Deals</div>
			<div>
				
				<el-link >Home</el-link>
				/
				<span >Today's Deals</span>
			</div> -->
			<el-image style="width: 100%; height: 400px;" fit="fill" :src="todayImg"></el-image>
		<!-- </div> -->
	
	<el-container>
		
		<el-main style="margin: 80px auto;" >
			<el-row :gutter="3" style="min-height: 500px;width: 1600px;margin:auto" v-loading="productLoading">
				<el-col v-if="productList && productList.length > 0" :xs="8" :sm="8" :md="6" :lg="4" :xl="4" :xxl="4"
				        style="margin-bottom: 20px; cursor: pointer" v-for="item in productList"
				        :key="item">
					<router-link :to="`/pages/productDetail?productId=${item.id}`">
						<el-popover :show-arrow="false" :offset="-270" width="160" popper-class="cc" placement="top">
							<template #reference>
								<el-card style="width: 260px;height: auto;border: 0;margin-bottom: 30px;" shadow="never" :body-style="{ padding: '0px' }">
									<div style="width: 240px;height: 240px;overflow: hidden;">
										<el-image :src="item.imgs.split(',')[0]" style="width: 240px;height: 275px;margin-left: 10px;max-width: 100%;max-height: 100%;" fit="cover" class="productImg">
									
										</el-image>
									</div>
									
									<div style="text-align: center;margin-top: 10px;">
										<el-text class="mx-1" size="large" style="font-weight: 600;font-size: 16px;">{{ item.name }}</el-text>
									</div>
									<div style="text-align: center;margin-top: 10px;">
										<el-text class="mx-1" size="large" style="font-weight: 600;font-size: 16px;">{{ item.description }}</el-text>
									</div>
									<div style="margin-left: 10px;text-align:center">
										<template v-for="label in labelList" :key="label.id">
											<el-tag v-if="label.productId === item.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
										</template>
										
									</div>
									<div style="text-align: center;margin-top: 10px;">
										<el-text class="mx-1" size="large" style="font-weight: 600;font-size: 16px;">${{
											floatToFixed2(item.price)
										}}
										</el-text>
										<el-text class="mx-1" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
											${{ floatToFixed2(item.oprice) }}
										</el-text>
									</div>
								</el-card>
							</template>
							<template #default>
								<el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
									<el-image
										:src="cartImg"
										@click="addCart(item)"
										style="width: 35px;height: 35px;"
										alt="cart"
									/>
								</el-button>
								<el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
									<el-image
										:src="collectImg"
										@click="addWishList(item)"
										style="width: 30px;height: 30px;"
										alt="collect"
									/>
								</el-button>
								<el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
									<el-image
										:src="compareImg"
										@click="addCompare(item)"
										style="width: 30px;height: 30px;"
										alt="compare"
									/>
								</el-button>
							</template>
						</el-popover>
					</router-link>
				</el-col>
				<el-col v-else style="text-align: center; line-height: 500px; font-size: 18px">
					no more products
				</el-col>
			</el-row>
			<el-pagination
				v-model:current-page="paginationData.page"
				v-model:page-size="paginationData.pageSize"
				:total="paginationData.total"
				@current-change="handleCurrentChange"
				background layout="prev, pager, next"
				style="margin-left: 45vw;margin-top: 80px;"/>
		</el-main>
	</el-container>
	
	<pc-footer></pc-footer>
	
	
	<!-- 多个spu类型弹窗	-->
	<el-dialog v-model="dialogVisible" title="Please select product spec"  center align-center >
		
		<div style="margin: auto;padding-top: 30px;display: flex;justify-content: space-between;">
      <el-card style="border: 0px;" shadow="never" v-if="product">
        <el-image
          style="width: 400px; height: 400px;border: 1px solid #000;border-radius: 10px;margin-left: 5px;"
          :src="productImg"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="imgList"
          :initial-index="4"
          fit="cover"
        />
        <el-row v-if="product.imgs">
          <el-col :span="4"  v-for="item in product.imgs.split(',')" :key="item">
            <el-link :underline="false">
              <el-image :src="item" style="width: 50px;height: 50px;margin-top: 10px;border: 1px solid #d7d5d5;" @click="chanageImg(item)"></el-image>
            </el-link>
           

            
          </el-col>
        </el-row>
      </el-card>

      <div style="margin-top: 10px;text-align: left;width: 500px;">
        <div style="font-size: 36px;color: #000;font-weight: 600;margin-bottom: 20px;">
          <span>{{product.name}}</span>
          
        </div>
        <div style="margin-top: 20px;">
          <!-- <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 30px;">Market Price: </el-text> -->
          <el-text style="font-size: 30px;color: #000;font-weight: 600;margin-right: 30px;">$ {{floatToFixed2(product.price)}}</el-text>
          <el-text tag="del" style="margin-right: 30px;color: rgb(204, 102, 102);font-weight: 600;font-size: 24px;">$ {{floatToFixed2(product.oprice)}}</el-text>
          
        </div>
        <!-- <div style="margin-bottom: 20px;">
          <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 55px;">Your Price: </el-text>
         
        </div> -->
        <div style="margin-left: 10px;">
			<template v-for="label in labelList" :key="label.id">
            <el-tag v-if="label.productId === product.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
          </template>
        </div>

        <div style="margin-top: 20px;margin-bottom: 20px;">
          <el-text style="font-size: 16px;color: #000;margin-right: 55px;">Sold: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.soldNum }}</span> </el-text>
          <el-text style="font-size: 16px;color: #000;margin-right: 55px;">Inventory: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.inventory }}</span></el-text>
        </div>

        <div v-if="attr && attr.length > 0" style="margin-top: 50px">
          <div v-for="item in attr" :key="item.key" style="margin-top: 20px">
            <div style="font-size: 20px;color: #000;font-weight: 500;margin-bottom: 10px;"> {{item.key}}</div>
            <el-radio-group v-model="chanceSpec[item.key]" size="large" @change="changeSpec(item.key)" >
              <template v-for="value in item.values" :key="value">
                <el-radio-button :label="value" :value="value" border style="margin-right: 30px;border-radius: 30px;" />
              </template>
              
            </el-radio-group>
          </div>
          
        </div>

       
        <div style="color: #000;margin-bottom: 20px;margin-top: 50px;">
          <el-text style="font-size: 20px;color: #000;font-weight: 600;margin-right: 55px;">Qty: </el-text>
          <el-input-number v-model="productNum" :min="1" :max="product.inventory" :step-strictly="true"  style="height: 50px;font-weight: 600;font-size: 30px;"/>
        </div>
        <div style="font-size: 30px;color: #fff;font-weight: 600;margin-bottom: 20px;margin-top: 20px;width: 380px;">
          

          <el-button round bg type="danger" style="width: 380px;height: 60px;font-size: 18px;font-weight: 600;margin-bottom: 10px;" @click="addCart">
            <el-icon size="30" style="vertical-align: middle;margin-right: 10px;">
              <ShoppingCartFull />
            </el-icon>
            Add to cart
          </el-button>

          <!-- <el-button type="success" text bg round style="width: 170px;height: 50px;font-size: 18px;font-weight: 600;">Add to wish list</el-button>
          
          <el-button type="warning" style="width: 170px;margin-left: 20px;font-weight: 600;font-size: 18px;height: 50px;" text bg round @click="addCompare">Add to compare</el-button> -->
        </div>
      </div>
    </div>
	</el-dialog>
</template>

<script setup>
import {ref, reactive, watch, defineOptions, onMounted, nextTick} from 'vue'
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import {floatToFixed2} from "@/utils/priceUtil"
import {getAgeList, getCategoryList, getProductAllByPage, getProductLabelAll, getSpuAll} from "@/api/spu"
import {doAddWishList} from "@/api/spu/wishList"
import cartImg from "@/assets/img/cart.png";
import collectImg from "@/assets/img/collect.png";
import compareImg from "@/assets/img/compare.png";

import todayImg from "@/assets/img/clrearance.png";
import {useRoute, useRouter} from "vue-router";
import {useDebounceFn} from '@vueuse/core'
import useCartCache from "@/store/cart";
import useCompareCache from "@/store/compare";
import { ElMessage,ElMessageBox  } from 'element-plus'
import {getAccessToken} from "@/utils/auth";


defineOptions({
	name: "Clearance"
})

const route = useRoute();
const router = useRouter();
const cartCacheStore = useCartCache()
const compareCacheStore = useCompareCache()

// 选择spu弹窗相关(dialogType 用于区分是那种弹窗  cart/compare/wishList)
const dialogVisible = ref(false);
const product = ref({});
const productImg = ref();
const productNum = ref(1);
const chanceSpec = ref({});
const spuList = ref([]);
const attr = ref([{
	key: "颜色",
	value: ["红色", "蓝色", "绿色"]
}, {
	key: "尺寸",
	value: ["S", "M", "L"]
}]);

const labelList = ref([])

const productLoading = ref(false);


// 年龄/分类
const ageOptions = ref([]);
const categoryOptions = ref([])
const productList = ref([])
const sortOptions = ref([
	{
		id: "mostPopular",
		name: "Most Popular"

	},
	{
		id: "newArrivals",
		name: "New Arrivals"

	},
	{
		id: "cheapest",
		name: "Cheapest"

	},
	{
		id: "expensive",
		name: "Expensive"

	},
	{
		id: "bestSalers",
		name: "Best Salers"

	},
])


// 表单数据
const formData = reactive({
	priceValue: [0, 1000],
	category: null,
	age: [],
	orderPriceSort: 'DESC',
	sort:"mostPopular"
})

// 分页数据
const paginationData = reactive({
	page: 1,
	pageSize: 18,
	total: 0
})

// 获取分类
const getCategory = async () => {
	const {data: {list}} = await getCategoryList({
		page: 1,
		pageSize: 20,
		conditions: []
	})
	categoryOptions.value = list;
	console.log(list, "分类数据");
}

// 获取年龄标签
const getAgeLabel = async () => {
	const {data: list} = await getAgeList({
		
		conditions: []
	})
	// list.forEach((item,index) => {
	// 	if(item.id == 9){
	// 		list.splice(index,1)
	// 	}
	// })
	ageOptions.value = list;
	console.log(list, "年龄分类数据");
}

const getLabelName = (id) =>{
	for (let i = 0; i < ageOptions.value.length; i++) {
		if(ageOptions.value[i].id == id){
			return ageOptions.value[i].name
		}
	}
}

// 查询商品(等待一秒之后查询，用于处理滑块频繁改变触发查询)
const queryProductList = useDebounceFn(async () => {
	productLoading.value = true
	const queryParam = await convertDataToQueryParam();
	const {data: {list = [], total = 0}} = await getProductAllByPage(queryParam);
	productList.value = list;
	paginationData.total = total;
	
	if(list && list.length > 0){
		let array = [];
		for (let i = 0; i < productList.value.length; i++) {
			array.push(productList.value[i].id)
		}

		//查询商品标签
		const {data:  labels} = await getProductLabelAll({
			conditions: [queryWrapper("productId", QueryCondition.IN, array)]
		})
		labelList.value = labels;
	}
	
	// 查询完成后浏览器跳到顶端
	document.documentElement.scrollTo({
		top: 0
	})
	productLoading.value = false
	console.log(list, "商品数据");
}, 1000)


// 查询商品条件转换
const convertDataToQueryParam = async () => {
	
	const urlQuery = route.query ? route.query : {};
	
	const queryParam = {
		page: paginationData.page,
		pageSize: paginationData.pageSize,
		conditions: []
	}
	
	// 处理关键词keyword(拼接名称和描述的模糊查询)
	// if (urlQuery && urlQuery.keyword) {
	// 	queryParam.conditions.push(queryWrapper("name", QueryCondition.ALL, route.query.keyword))
	// 	// queryParam.conditions.push(queryWrapper("description", QueryCondition.ALL, route.query.keyword ))
	// }
	
	// // 转换价格区间(美元转美分)
	// if (urlQuery.minOldPrice && urlQuery.maxOldPrice) {
	// 	queryParam.conditions.push(queryWrapper("price", QueryCondition.GT, Number(urlQuery.minOldPrice) * 100))
	// 	queryParam.conditions.push(queryWrapper("price", QueryCondition.LT, Number(urlQuery.maxOldPrice) * 100))
	// }
	
	// // 转换分类
	// if (urlQuery.categoryId) {
	// 	queryParam.conditions.push(queryWrapper("categoryId", QueryCondition.EQ, urlQuery.categoryId))
	// }
	
	// 转换年龄（标签）
	// if (urlQuery.labelId) {
		queryParam.conditions.push(await convertAgeLabel(urlQuery.labelId))
	// }
	
	//  转换价格排序
	// if (urlQuery.sort === 'mostPopular') {
	// 	queryParam.conditions.push(queryWrapper("sort", QueryCondition.DESC, []))
	// } else if(urlQuery.sort === 'expensive'){
	// 	queryParam.conditions.push(queryWrapper("price", QueryCondition.DESC, []))
	// } else if(urlQuery.sort === 'newArrivals'){
	// 	queryParam.conditions.push(queryWrapper("create_time", QueryCondition.DESC, []))
	// } else if(urlQuery.sort === 'bestSalers'){
	// 	queryParam.conditions.push(queryWrapper("sold_num", QueryCondition.DESC, []))
	// } else if(urlQuery.sort === 'cheapest'){
	// 	queryParam.conditions.push(queryWrapper("price", QueryCondition.ASC, []))
	// }
	
	
	return queryParam;
}


// form数据转化为url query
const convertFormDataToUrlQuery = async () => {
	
	const urlQuery = route.query ? {...route.query} : {}
	
	// 转换价格区间(美元转美分)
	if (formData.priceValue && formData.priceValue.length > 0) {
		urlQuery.minOldPrice = formData.priceValue[0];
		urlQuery.maxOldPrice = formData.priceValue[1];
	} else {
		delete urlQuery.minOldPrice;
		delete urlQuery.maxOldPrice;
	}
	
	// 转换分类
	if (formData.category) {
		urlQuery.categoryId = formData.category;
	} else {
		delete urlQuery.categoryId;
	}
	
	// 转换年龄（标签）
	if (formData.age && formData.age.length > 0) {
		urlQuery.labelId = formData.age;
	} else {
		delete urlQuery.labelId;
	}
	
	//  转换价格排序
	// if (formData.orderPriceSort === 'DESC') {
	// 	urlQuery.sort = 'DESC';
	// } else {
	// 	urlQuery.sort = 'ASC';
	// }
	
	urlQuery.sort = formData.sort;
	
	console.log(urlQuery, "urlQuery");
	
	await router.replace({
		query: urlQuery
	})
	
}

//  单独处理年龄，先查询所有的标签商品，再通过所有的标签商品id查询商品表
const convertAgeLabel = async (labelId) => {
	// 查询所有的商品标签
	const queryParam = {conditions: []}
	queryParam.conditions.push(queryWrapper("labelId", QueryCondition.EQ, 9))
	const {data = []} = await getProductLabelAll(queryParam);
	
	// 循环获取对应的商品id,并返回对应的条件
	const productIds = [];
	data.forEach(item => {
		productIds.push(item.productId)
	})
	
	// 存在没数据的情况，导致后台查询报错
	if (productIds.length === 0) productIds.push("")
	return queryWrapper("id", QueryCondition.IN, productIds)
}

// 处理页签变化
const handleCurrentChange = (pageVal) => {
	paginationData.page = pageVal;
	queryProductList();
}


// 判断是否从头部导航或者搜索栏进入(通过不存在maxOldPrice属性判断)
const checkInitFromSearch = () => {
	const query = route.query ? route.query : {};
	
	if (query.maxOldPrice === undefined) {
		return true;
	}
}

// 每次重新输入关键词或者点击导航栏重置查询条件
const restFormDataAndPaginationData = () => {
	const query = route.query ? route.query : {}
	
	formData.priceValue = [0, 1000];
	formData.category = query.categoryId ? Number(query.categoryId) : null;
	formData.age = query.labelId ? [Number(query.labelId)] : [];
	formData.orderPriceSort = 'DESC';
	formData.sort = query.sort ? query.sort : 'mostPopular';
	
	paginationData.page = 1;
	paginationData.pageSize = 18;
	paginationData.total = 0;
}


// 转化数据为实际可选项
const convertSpec = (spuList) => {
	const specList = []
	spuList.forEach(item => {
		const specDesc = item.specDesc ? JSON.parse(item.specDesc) : []; // 转化JSON 字符串 [{key:string, value:string}]
		item.specDesc = specDesc // 转化JSON 字符串
		if(specDesc){
			specDesc.forEach(spec => {
			
				// 判断key是否已经存在
				const index = specList.findIndex(item => item.key === spec.key);
				if (index === -1) {
					specList.push({
						key: spec.key,
						value: [spec.value]
					})
				} else {
					// 判断value是否存在, 不存在就添加
					const valueIndex = specList[index].value.indexOf(spec.value);
					if (valueIndex === -1) specList[index].value.push(spec.value);
				}
			})
		}
		
	})
	
	return specList;
}

// 用于判断是否需要打开弹窗(判断商品对应的spu是否存在多条)
const checkOpenDialog = async (item) => {

	product.value = item;
	productImg.value = item.imgs.split(',')[0]
	const {data = []} = await getSpuAll({
		conditions: [queryWrapper("productId", QueryCondition.EQ, item.id),queryWrapper("sort", QueryCondition.DESC, "")]
	});
	spuList.value = data;
	if (data && data.length > 1) {
		dialogVisible.value = true;
		// specTypeList.value = convertSpec(data);
		attr.value = JSON.parse(item.attr);

      const specDesc = JSON.parse(data[0].specDesc);
	  if(specDesc){
		for(let i = 0; i < specDesc.length; i++){
			const spec = specDesc[i];
			chanceSpec.value[spec.key] = spec.value;
		}
	  }
      

      product.value.soldNum = data[0].soldNum;
      product.value.inventory = data[0].inventory;
      product.value.oprice = data[0].oprice;
      product.value.price = data[0].price;
	  return true;
	}
	return false
}


// 处理添加购物车
const addCart = async (item) => {
	if(!dialogVisible.value){
		item.num = 1;
		const dialogVisible = await checkOpenDialog(item)
		// 如果打开弹窗直接返回
		if (dialogVisible) return;
		if(spuList.value[0].inventory < item.num){
			ElMessage({
			message: "Out of stock",
			type: "error"
			});
			return;
		}
		
		// 未打开直接添加购物车，并写入productSpecId
		const specDesc = spuList.value[0].specDesc ? JSON.stringify(spuList.value[0].specDesc) : [];
		item.inventory = spuList.value[0].inventory
		await cartCacheStore.addCartItem({...item, productSpecId: spuList.value[0].id, specDesc: specDesc})
		cartCacheStore.cartDrawer = true
	}else{
		product.value.num = productNum.value
		
		let spec ={}
		let array = []
		for (const [key, value] of Object.entries(chanceSpec.value)) {
			const s = {
			key: key,
			value: value
			};
			array.push(s);
		}
		spuList.value.forEach((value) => {
			
			if(value.specDesc == JSON.stringify(array)){
			
			spec = value;
			return;
			}
		});
		if(!spec.id || spec.inventory < productNum.value){
			ElMessage({
			message: "Out of stock",
			type: "error"
			});
			return;
		}
		await cartCacheStore.addCartItem({...product.value, productSpecId: spec.id, specDesc: array})
		cartCacheStore.cartDrawer = true
	}
	ElMessage({
          message: "Successfully added to shopping cart",
          type: "success"
        });
	
}

// 处理添加比较
const addCompare = (item) => {
	//compareCacheStore.addCompareItem(item)
	var compareList = JSON.parse(localStorage.getItem("compareList")) || [{}];
	var flag = true

	
	for(let i = 0; i < compareList.length; i++){
		if(compareList[i].id === item.id){
			flag = false;
			break;
		}
	}
	
	if(flag){
		
		compareList.push(item);
		localStorage.setItem("compareList", JSON.stringify(compareList));
		ElMessageBox.confirm(
			'商品成功加入对比列表，是否前往查看?',
			'success',
			{
			confirmButtonText: 'OK',
			cancelButtonText: 'Cancel',
			type: 'success',
			}
		).then(() => {
			router.push({path: '/pages/compare',})
		})
		.catch(() => {
		
		})
	}else{
		
		ElMessage({
			message: "该商品已存在于比较列表中",
			type: "warning"
		});
	}
}


// 监听地址栏变化
watch(() => route.query, async (data) => {
	// 判断是否来源于搜索或者导航栏，直接重置所有查询条件，然后通过变更formData数据来触发地址栏的变更
	const isFromSearch = checkInitFromSearch()
	if (isFromSearch) {
		restFormDataAndPaginationData();
		return
	}
	
	await queryProductList()
}, {
	immediate: true,
	deep: true
})

// 监听formData 数据变化
// watch(formData, async (data) => {
// 	await convertFormDataToUrlQuery()
// }, {
// 	immediate: true
// })

onMounted(async () => {
	await getCategory(); // 获取分类
	await getAgeLabel(); // 获取年龄
	await queryProductList()
})

const chanageImg = (item) =>{
      productImg.value = item
 }

 const changeSpec = () =>{
      let array = []
     
      for (const [key, value] of Object.entries(chanceSpec.value)) {
        const s = {
          key: key,
          value: value
        };
        array.push(s);
      }
	  product.value.soldNum = 0;
          product.value.inventory = 0;
          product.value.oprice = 0;
          product.value.price = 0;
      spuList.value.forEach((value) => {
        
        if(value.specDesc == JSON.stringify(array)){
          
          product.value.soldNum = value.soldNum;
          product.value.inventory = value.inventory;
          product.value.oprice = value.oprice;
          product.value.price = value.price;
          return;
        }
      });
    }

	const addWishList = async (item) => {
		
      if(getAccessToken()){
		await doAddWishList({productId: item.id});
		ElMessage({
			message: "加入收藏成功",
			type: "success"
		});
      }else{
		ElMessageBox.confirm(
          'You are not logged in. Would you like to log in now?',
          'Error',
          {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'error',
          }
        ).then(() => {
			router.push({path: '/pages/login',query:{
				redirect: router.currentRoute.value.fullPath
			}})
        })
        .catch(() => {
        
        })
	  }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-container /deep/ .el-form-item__content {
	justify-content: flex-end;
}

.productImg:hover {
	transform: scale(1.2);
}

.productImg {
	transition: transform 0.3s ease-in-out;
	
}
</style>
